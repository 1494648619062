/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "jquery/dist/jquery.min.js"
import "popper.js/dist/popper.min"
import "bootstrap/js/dist/util"
import "bootstrap/js/dist/carousel"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/collapse"
